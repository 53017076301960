// useEvents.js
import { DEFAULT_LOCALE } from "@/utils/global";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const PER_PAGE = 21;

export function useEvents(start, end, critereType, critereString) {
  const [calendarEvents, setCalendarEvents] = useState(null);
  const { locale } = useRouter();
  const [page, setPage] = useState(0);
  const loadEvents = async () => {
    let newPage = page;

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const parsedStartDate = new Date(start);
    // Get the parsedStartDate in the format YYYY-MM-DD plus one day
    const parsedStartDatePlusOneDay = new Date(
      parsedStartDate.setDate(parsedStartDate.getDate() + 1)
    );

    let apidaeUrl = `/api/apidae/get-events?start=${
      start ? start : `${year}-${month}-${day}`
    }&first=${PER_PAGE * newPage}&count=${PER_PAGE}&locales=${DEFAULT_LOCALE}${
      locale === "en" ? ",en" : `,en`
    }${locale !== "en" && locale !== DEFAULT_LOCALE ? `,${locale}` : ""}`;
    if (end) {
      apidaeUrl += `&end=${end}`;
    } else if (start) {
      apidaeUrl += `&end=${start}`;
    }
    if (critereType) {
      apidaeUrl += `&criteretype=${critereType}`;
    }
    if (critereString) {
      apidaeUrl += `&critere=${critereString}`;
    }

    const fetchData = await fetch(apidaeUrl);

    const newData = await fetchData.json();

    if (!fetchData.ok) {
      throw new Error("ApidaeData", newData);
    }

    newPage = newPage + 1;
    setCalendarEvents(newData);
  };

  useEffect(() => {
    loadEvents();
  }, [start, end, critereString, page, locale]);

  return calendarEvents;
}
