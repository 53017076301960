import cache from "@/utils/middleware/cache";
const getSelections = async () => {
  const apiUrl = "https://api.apidae-tourisme.com/api/v002/referentiel/selections";
  const query = JSON.stringify({
    projetId: process.env.APIDAE_PROJECT_ID,
    apiKey: process.env.APIDAE_API_KEY
  });

  const response = await fetch(`${apiUrl}?query=${query}`);
  if (!response.ok) {
    throw new Error(`Error fetching selections: ${response.statusText}`);
  }
  const data = await response.json();

  return data.map((selection) => selection.id); // Assurez-vous que 'id' est correct dans la réponse
};
const handler = async (req, res) => {
  const { search, keywords, per_page, first, page, locale } = req.query;

  if (!search) {
    return res.status(400).json({
      error: "Missing search term",
    });
  }

  const cacheKey = encodeURIComponent(req.url);

  if (req.cache && req.cache.has(cacheKey)) {
    console.log("Get storyblok story from CACHE", cacheKey);
    const { headers, data } = req.cache.get(cacheKey);
    res.setHeader(
      "Cache-Control",
      `public,max-age=${process.env.CACHE_MAX_AGE_IN_S}`
    );
    res.setHeader("X-Cache", "HIT");

    return res.json(data);
  }

  try {
    let searchData = null;
    let searchResult = null;
    if (page > 0) {


      const searchParams = `?token=${
        process.env.STORYBLOK_API_TOKEN
      }&version=published&per_page=${per_page ? per_page / 2 : 10}&page=${
        page ? page : 1
      }&search_term=${search}&language=${locale}&filter_query[component][not_in]=activities_list,filter_simple,header,footer,settings&excluding_slugs=app/*`;

      // console.log("Search params", searchParams);

      const searchResult = await fetch(
        `https://api.storyblok.com/v2/cdn/stories${searchParams}`
      );
      const data = await searchResult.json();

      if (data) {
        searchData = data;
      }
    }
   const selectionIds = await getSelections();
 const selectionIdsString = JSON.stringify(selectionIds);
    let responseTouristiques = null;

    // Now fetch content frm apidae calling the get-activity API
    try {
      const response = await fetch(
          `${
              process.env.SITE_URL
          }/api/apidae/get-activity?territoireIds=82490&searchQuery=${search}&count=${
              searchData?.stories?.length < 2 ? 4 - searchData?.stories?.length : 2
          }&first=${first ?? 0}&locales=${locale},en,fr&selectionIds=${encodeURIComponent(
              selectionIdsString
          )}`
      );

      console.log(`${
          process.env.SITE_URL
      }/api/apidae/get-activity?territoireIds=82490&searchQuery=${search}&count=${
          searchData?.stories?.length < 2 ? 4 - searchData?.stories?.length : 2
      }&first=${first ?? 0}&locales=${locale},en,fr`)

      if (!response.ok) {
        throw new Error("onSeeMore", response);
      }

      const responseJson = await response.json();
      responseTouristiques = responseJson;
    } catch (error) {
      console.error("Error search Apidae:", error);
    }

    res.setHeader("Cache-Control", `no-cache`);
    res.setHeader("X-Cache", "MISS");

    res.status(200).json({
      storyblok: Object.assign({}, searchData, {
        perPage: searchResult?.perPage,
        total: searchResult?.total,
      }),
      apidae: responseTouristiques,
    });
  } catch (error) {
    console.error("Error search storyblok:", error);

    return res.status(500).json({
      error: "Get storyblok story: " + error.error,
      error_description: error.error_description,
    });
  }
};

export default cache(handler);
