import React from "react";
import Image from "next/image";
import ImageCalendarCard from "@/public/card-img.png";
import Text from "../../Core/Text";
import ArrowLink from "@/common/components/Core/ArrowLink";
import Link from "next/link";
import * as styles from "./CardBlog.module.scss";
import useTranslation from "next-translate/useTranslation";
import CustomLink from "../../CustomLink";
export default function CardBlog(props) {
  const { t } = useTranslation('["common", "blog", "calendar"]');
  const { data, highlight = false, className } = props;

  function formatDate(date) {
    if (typeof date === "string") {
      date = new Date(date);
    }
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    // Utilisez 't' pour traduire le mois
    return `${day} ${t(`calendar:month-${month.toLowerCase()}`)} ${year}`;
  }


  return (
    <CustomLink link={`${data.fullslug}`}>
      <div className={`flex flex-col group cursor-pointer overflow-hidden`}>
        <div className="relative">
          <Image
            className={`object-cover w-full h-full rounded-[16px] min-w-full ${styles.mainImg
              } ${!highlight ? "aspect-[11.5/12]" : ""}`}
            src={`http://${data.image.substring(2)}`}
            alt={"missing alt"}
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
            fill
          />
        </div>
        <div className={"flex flex-col col-span-3 py-6 flex-grow relative"}>
          <div className={"flex flex-row items-center gap-4"}>
            {data &&
              data.categoryLabels &&
              data.categoryLabels.map(
                (category, index) => (
                  <Text
                    as="ui2"
                    key={index}
                    className="mb-3 md:mb-5 uppercase text-text-h4"
                  >
                    {category}
                  </Text>
                )
              )}
          </div>
          <Text
            as={`${highlight ? "h5" : "h6"}`}
            className="mb-1 max-w-[385px]"
          >
            {data.title}
          </Text>
          {highlight && data && data.shortDescription && (
            <Text as="body2" className="max-w-[555px]">
              {data.shortDescription}
            </Text>
          )}
          <div className={"mt-3 md:mt-6 mb-5 md:mb-7"}>
            {data && data.date && (
              <Text as="ui1" className="text-text-h4 mb-1">
                {t("blog:published-date")} {formatDate(data.date)}
              </Text>
            )}
            {data && data.timeToRead && (
              <Text as="ui1" className="text-text-h4">
                {t("blog:reading-time")} : {data.timeToRead}
              </Text>
            )}
          </div>
          <ArrowLink className="mt-auto mb-0" href={data.fullslug}>
            {t("common:actions.explore")}
          </ArrowLink>
        </div>
      </div>
    </CustomLink>
  );
}
