import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import * as styles from "./ActivityFilterDropdown.module.scss";
import { upperCaseFirstLetter } from "@/utils/format";
import { getLangValue } from "@/utils/global";

const { default: Icon } = require("@/utils/Icon");
const { default: Checkbox } = require("../Core/Checkbox");

const ActivityFilterDropdown = ({
                                    filter,
                                    selectedFilters,
                                    datafilter,
                                    selection,
                                    onFilterActivities,
                                    onSelectFilter,
                                }) => {
    const refFilterDropdown = useRef(null);
    const { locale } = useRouter();
    const [filterOpen, setFilterOpen] = useState(false);
    const [resultsByElement, setResultsByElement] = useState({}); // État pour les résultats par élément

    const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;
    function findElementById(data, targetId) {
        for (const category of data) {
            const foundElement = category.elementReferences.find((element) => element.id === targetId);
            if (foundElement) {
                return foundElement;
            }
        }
        return null; // Si aucun élément n'est trouvé
    }

    const checkApidaeResults = async (filterId) => {

        const result = findElementById(datafilter, filterId);
        console.log(filterId)



        const response = await
            fetch(`/api/apidae/get-nb-result?&slug=${result?.elementReferenceType}&id=${result?.id}&selection=${selection}`);
        const data = await response.json();
        return data && data.numFound > 0;



    };

    useEffect(() => {
        const checkResultsForElements = async () => {
            if (filter?.elementReferences?.length > 0) {
                const results = {};

                // Vérifie chaque élément individuellement
                for (const element of filter.elementReferences) {
                    const hasResult = await checkApidaeResults(element.id);
                    results[element.id] = hasResult; // Stocke le résultat pour chaque élément
                }
                setResultsByElement(results);
            }
        };
        checkResultsForElements();
    }, [filter]);

    const handleClickOutside = useCallback(() => {
        if (filterOpen) {
            setFilterOpen(false);
        }
    }, [filterOpen]);

    useEffect(() => {
        const handler = (e) => {
            const element = refFilterDropdown.current;
            if (element && !element.contains(e.target)) {
                handleClickOutside();
            }
        };

        document.addEventListener("click", handler);

        return () => {
            document.removeEventListener("click", handler);
        };
    }, [handleClickOutside]);

    const handleClickFilter = () => {
        if (!filterOpen) {
            setFilterOpen(true);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            setFilterOpen(true);
        }
    };

    const handleAddFilter = (value) => {
        onSelectFilter([...selectedFilters, value]);
        onFilterActivities([...selectedFilters, value]);
    };

    const handleToggleFilter = (type, value) => {
        if (
            selectedFilters.find((filter) => filter.id === value.id) ||
            selectedFilters.includes(value)
        ) {
            onSelectFilter(selectedFilters.filter((item) => item.id !== value.id));
            onFilterActivities(selectedFilters.filter((item) => item.id !== value.id));
        } else {
            handleAddFilter(value);
        }
    };

    return (
        <div ref={refFilterDropdown} className="relative">
            <button
                onClick={handleClickFilter}
                onKeyDown={handleKeyDown}
                type="button"
                id="filterMenuButton"
                className={`${styles.dropdownTrigger} flex items-center cursor-pointer gap-3`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={filterOpen}
            >
                {filter?.label}
                <Icon icon="icon-filter-24" size={24} />
            </button>
            <div
                className={`${styles.dropdownMenu} hidden absolute right-0 p-4 lg:p-6 rounded-md drop-shadow-md z-10`}
                aria-labelledby="filterMenuButton"
            >
                {filter.elementReferences?.map((element, elementIndex) => (
                    resultsByElement[element.id] ? (
                        <Checkbox
                            key={`${elementIndex}-${element.elementReferenceType}`}
                            item={{
                                id: element.id,
                                label: getLangValue(element, langKeyTrad),
                                elementReferenceType: element.elementReferenceType,
                            }}
                            index={elementIndex}
                            selectedItems={selectedFilters}
                            handleChange={handleToggleFilter}
                            type={element.elementReferenceType}
                            filterSlug={filter.slug}
                            className="mt-2"
                        />
                    ) :   null
                ))}
            </div>
        </div>
    );
};

export default ActivityFilterDropdown;
