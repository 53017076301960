import { useCallback, useEffect, useRef, useState } from "react";
import Image from "next/image"
import * as styles from "./DrawerPage.module.scss";
import * as stylesDrawer from "@/common/components/Modals/Drawer.module.scss";
import Text from "@/common/components/Core/Text";
import { SEASON_WINTER } from "@/utils/global";
import useEmblaCarousel from "embla-carousel-react";
import WebcamItem from "@/common/components/Modals/DrawerWebcam/WebcamItem";
import { useRouter } from "next/router";
import Icon from "../../../Core/Icon";
import { slugify } from "@/utils/format";
import ArrowLink from "../../../Core/ArrowLink";

const WebcamModule = (props) => {
  const { blok, drawerData } = props;
  const [webcams, setWebcams] = useState([]);
  const [selectedWebcam, setSelectedWebcam] = useState(0);
  const [renderedWebcams, setRenderedWebcams] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [seasonMode, setSeasonMode] = useState(SEASON_WINTER);
  const router = useRouter();

  const id_lumiplan = router.query.id;

  const activeWebcam = router.query.webcam ? router.query.webcam : null;

  const webcams_order = blok?.webcams_order;
  console.log(webcams_order)

  const orderedWebcams = webcams_order
  ? webcams.slice().sort((a, b) => webcams_order.indexOf(a.id.toString()) - webcams_order.indexOf(b.id.toString()))
  : webcams;



  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    containScroll: "keepSnaps",
  });

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        const index = emblaApi.selectedScrollSnap();
        const webcamId = webcams[index]?.id;
        setSelectedIndex(index);
        setSelectedWebcam(webcamId);
      });
    }
  }, [emblaApi, webcams]);

  useEffect(() => {
    if (emblaApi) {
      const index = webcams.findIndex((webcam) => webcam.id === selectedWebcam);
      if (index !== -1) {
        emblaApi.scrollTo(index);
      }
    }
  }, [emblaApi, selectedWebcam, webcams]);

  const onChangeSelectedWebcam = useCallback(
    (index, webcamId) => {
      // When clicked on a list item, set the selected index to the clicked item
      // Then scroll to that item
      setSelectedIndex(index);
      setSelectedWebcam(webcamId);
    }
  );

  useEffect(() => {
    const fetchWebcams = async () => {
      const data = drawerData?.data;

      if (data?.content?.resorts?.length > 0) {
        setWebcams(data.content.resorts[0]?.webcams || []);

        if (!id_lumiplan && !activeWebcam) {
          setSelectedWebcam(data.content.resorts[0]?.webcams[0]?.id || 0);
        }
        if (activeWebcam) {
          const webcam = data.content.resorts[0]?.webcams.find(
            (webcam) => {
              const slug = slugify(webcam.name.data[0].data.split(' - ')[0]);
              return activeWebcam === slug
            }
          );
          setSelectedWebcam(webcam?.id || 0);
        }
      }
    };

    let seasonMode = SEASON_WINTER;
    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }
    setSeasonMode(seasonMode);

    fetchWebcams();
  }, [drawerData]);


  const images =
    blok?.fond?.length > 0 ? blok?.fond : [];
  const image =
    seasonMode === SEASON_WINTER
      ? images[0]
      : images.length > 1
        ? images[1]
        : images[0];

  const webcamtTitle = (title) => {
    return title.split('-')
  }

  return <div className={`${styles.lumiplanModule} ${styles.webcamModule}`}>
    <div className={`${styles.drawerBg} absolute top-0 inset-0`}>
      {blok && (
        <Image
          src={image?.filename || "/meteo-desktop-bg.png"}
          alt={image?.alt || "missing alt"}
          fill
          sizes="(max-width: 768px) 80vw, (max-width: 1024px) 70vw, 50vw"
          className="object-cover"
        />
      )}
    </div>

    <div className="container relative overflow-hidden">

      <div className="mt-4 lg:mt-16 lg:grid grid-cols-12 gap-x-3 w-full">
        <div className={`${stylesDrawer.leftList} lg:col-span-4 lg:flex flex-col justify-between lg:!h-full`}>
          <div>
            <Text as="h1" tag="h1" className="">
              {blok?.title}
            </Text>
            {blok?.subtitle && <Text as="ui2" className="uppercase mb-6 lg:mb-10">
              {blok?.subtitle}
            </Text>}
          </div>


          {blok?.short_description && <div className={styles.webcamModuleShortDescription}>
            <Text as="body2">
              {blok?.short_description}
            </Text>
          </div>}
            <div className="flex whitespace-nowrap overflow-auto mobile-overflow-container lg:whitespace-normal lg:overflow-hidden gap-x-3 lg:mb-4 lg:flex-col lg:gap-y-2 items-center lg:items-start">
                {orderedWebcams.map((webcam, index) => (
                  <ArrowLink
                    key={`dwc${index}`}
                    onClick={() => onChangeSelectedWebcam(index, webcam.id)}
                    className={`${selectedIndex === index ? "" : "opacity-50"
                      } hover:opacity-100 capitalize`}
                    variant="reverse"
                    noColorChange={true}
                    sizeText={'h5'}
                    active={selectedIndex === index}
                  >
                    <span className="capitalize font-normal align-sub">
                      {webcamtTitle(webcam.name.data[0].data)[0]}
                      {console.log("webcam id : ", webcam.id)}
                    </span>
                  </ArrowLink>
                ))}
                {orderedWebcams[selectedWebcam] && (
                  <div
                    className="hidden lg:flex"
                    key={`swd${orderedWebcams[selectedWebcam].id}`}
                  >
                    <Text as="body3">
                      {orderedWebcams[selectedWebcam].description}
                    </Text>
                  </div>
                )}
              </div>
        </div>

        <div className="embla__viewport h-full w-full lg:col-span-8 overflow-hidden" ref={emblaRef}>
          <div className="embla__container">
            {webcams.map((webcam, index) => (
              <div
                className={`embla__slide relative ${index === webcams.length - 1 ? "mr-0" : "mr-20"
                  }`}
                key={index}
              >
                <div className={`
                  hidden lg:inline-flex absolute top-5 left-5
                  ${selectedIndex === index ? "opacity-100" : "opacity-0"}
                `}>
                  <div className={'pointer-events-none transition-opacity duration-300 z-1 bg-button-h1 px-4 py-2 rounded-full'}>
                    {webcamtTitle(webcam.name.data[0].data)[0]}
                  </div>
                  {webcamtTitle.length > 0 && (
                    <div className={'pointer-events-none transition-opacity duration-300 z-1 bg-transparent px-4 py-2 ml-1 rounded-full border'}>
                      {webcamtTitle(webcam.name.data[0].data)[1]}
                    </div>
                  )}
                </div>
                <WebcamItem
                  key={`${webcam.id}-${index}`}
                  webcam={webcam}
                  renderedWebcams={renderedWebcams}
                  onSetRenderedWebcams={setRenderedWebcams}
                />
              </div>
            ))}
          </div>
        </div>
        {webcams[selectedIndex] && webcams[selectedIndex].description && (
          <div
            className="mt-14 lg:hidden"
            key={`swd${webcams[selectedIndex].id}`}
          >
            <Text as="body3">{webcams[selectedIndex].description}</Text>
          </div>
        )}
      </div>
    </div>
  </div>
}

export default WebcamModule