import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Head from "next/head";
import React, { useState, useEffect } from "react";
import ImageBGTop from "@/public/webcam-bg.png";
import Text from "../../../Core/Text";
import Image from "next/image";
import ArrowLink from "@/common/components/Core/ArrowLink";
import Button from "@/common/components/Core/Button";
import { SEASON_WINTER } from "@/utils/global";
import useTranslation from "next-translate/useTranslation";
export default function CmsPage({ blok, settings, nestedData, seoTitle }) {

  const { t } = useTranslation(["404", "common"]);

  let title =
    seoTitle && seoTitle !== ""
      ? seoTitle + ` - ${t("common:courchevel")}`
      : t("common:courchevel");
  let description =
    seoTitle && seoTitle !== ""
      ? seoTitle + ` - ${t("common:courchevel")}`
      : t("common:courchevel");
  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }

  const [imageBySeason, setImageBySeason] = useState(null);

  useEffect(() => {
    let seasonMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }

    const images = blok.images?.length > 0 ? blok.images : [blok.images];
    const image =
      seasonMode === SEASON_WINTER
        ? images[0]
        : images.length > 1
          ? images[1]
          : images[0];

    setImageBySeason(image);
  }, [blok]);

  return (
    <div className="" {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      <div className="pt-24 pb-10 relative min-h-screen flex">
        <div className="absolute top-0 bottom-0 left-0 right-0 z-0 bg-no-repeat bg-cover bg-black lg:bg-gradient-to-t from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,1)] to-50%">
          {imageBySeason?.filename && (
            <Image
              className={`absolute top-0 bottom-0 left-0 right-0 object-cover object-center w-full h-full opacity-60`}
              src={imageBySeason.filename}
              fill
              alt={"missing alt"}
            />
          )}
        </div>
        <div
          className={"container relative text-white flex flex-col justify-end"}
        >
          <Text
            as={"h1"}
            className={
              "text-[96px] leading-[96px] lg:text-[196px] lg:leading-[196px]"
            }
          >
            {t("404:title")}
          </Text>
          <Text as={"h5"} className={"pb-5 max-w-[250px] lg:max-w-full"}>
            {t("404:not-available")}
          </Text>
          <div className={"lg:flex"}>
            {blok.cta && blok.cta[0] && (
              <Button
                tag="link"
                href={`${blok?.cta[0]?.link.cached_url}`}
                link={blok?.cta[0]?.link}
                className={"w-full lg:w-auto"}
              >
                {blok?.cta[0]?.label}
              </Button>
            )}
            <div className="flex mt-6 lg:mt-0 lg:pl-20 lg:items-center flex-wrap">
              <Text className="w-full lg:w-auto" as={"body2"}>
                {t("404:redirect-options-title")}:
              </Text>

              {blok?.bouton.map((item, index) => (
                <ArrowLink
                  key={index}
                  className="lg:pl-4 mt-2 lg:mt-0"
                  link={item.link}
                >
                  {item.label}
                </ArrowLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
